// Here you can add other styles
.react-toast-notifications__container {
  z-index: 9999 !important;
}

.button-icon {
  flex: 0 0 4rem;
  height: 1.25rem;
  font-size: 1.25rem;
  text-align: center;
  fill: currentColor;
  transition: inherit;
}
.btn-whatsapp {
  color: #fff;
  background-color: #1ad03f;
  border-color: #1ad03f;
}
.btn-whatsapp:focus, .btn-whatsapp:hover {
  color: #fff;
  background-color: #51c65e;
  border-color: #51c65e;
}

.disabled-button,
.disabled-button:hover,
.disabled-button:focus,
.disabled-button:active {
  background: #F7F7F8;
  border: 0;
  color: #CCCCCC;
}
.disabled-button:active:focus{
  box-shadow: none;
}

.info {
  position: relative;
  display: inline-block;
}
.info .tooltiptext {
  visibility: hidden;
  min-width: 180px;
  width: 20vw;
  background-color: #555;
  color: #fff;
  text-align: center;
  padding: 5px;
  border-radius: 6px;

  position: absolute;
  z-index: 1;
  bottom: 125%;
  right: -50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}
.info .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  right: 5%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}
.info:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
.info img {
  width: 15px;
  height: 15px;
}
.info-float-right{
  float: right;
}
.tabtab-list-container li {
  max-width: calc(100% / 4);
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 100px;
  text-align: center;
}
@media screen and (max-width: 1260px) {
  .dashboard-widget-title{
    font-size: 12px;
  }
}
